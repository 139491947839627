import { message } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../../@hooks/useAuth";

const LogoutLink = (props) => {
    let auth = useAuth();
    const [logoutSuccessful, setLogoutSuccessful] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const isAdmin = auth.admin;

    useEffect(() => {
        if (logoutSuccessful) {
            
            history.push(isAdmin?"/admin/login":"/login", {
                from: { pathname: location.pathname },
            });
        }
    }, [logoutSuccessful]);

    return (
        <a
            href="#"
            onClick={(e) => {
                e.preventDefault();
                if (logoutSuccessful === null) {
                    setLogoutSuccessful(false);
                    const closeMessage = message.loading("Logging out...", 0);
                    auth.signout()
                        .then(() => {
                            closeMessage();
                            setLogoutSuccessful(true);
                        })
                        .catch((err) => {
                            closeMessage();
                            setLogoutSuccessful(null);
                        });
                }
            }}
        >
            {props.children}
        </a>
    );
};

export { LogoutLink };
