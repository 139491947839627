import { Card, List } from "antd"
import { formatCurrency } from "../../../utils/format"

const ServiceAreaTable = ({items}) => {
    return (
        <Card>
        <List itemLayout="vertical">
        {items?.map((item,idx)=>{
            return (
                <List.Item extra={formatCurrency(item.price?.amount)} key={item.ordinal}>{item.name}</List.Item>
            );
        })}
        </List>
        </Card>
    );
}

export {ServiceAreaTable}