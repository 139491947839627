import { FormOutlined, UnorderedListOutlined } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { Button, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../@hooks/useAuth";

const ContainerContent = ({ user}) => {
    return (
        <React.Fragment>
            <Typography>
                <Title>Hi, {user.name}!</Title>
                <Paragraph>Welcome to Unique Vtour Client Portal.</Paragraph>
            </Typography>
        </React.Fragment>
    );
};

function WelcomePageComponent(props) {
    let auth = useAuth();
    
    return (
        <PageContainer  content={<ContainerContent key="content" user={auth.user} />}>
            
            <ProCard key={0} ghost wrap gutter={[24,30]}>
                
                <ProCard key="create-booking-card" ghost hoverable colSpan={{ sm: 24, md: 12 }}>
                    <Link to="/bookings">
                        <Button block type="primary" size="large" style={{ height: "100px" }} icon={<FormOutlined />}>
                            Create booking
                        </Button>
                    </Link>
                </ProCard>
            </ProCard>
            
        </PageContainer>
    );
}

export { WelcomePageComponent as AdminWelcomePage };
