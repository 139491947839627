import React from "react";
import { Avatar, Menu, Dropdown, Badge, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";

import ProLayout, { DefaultFooter } from "@ant-design/pro-layout";
import defaultProps from "./_defaultProps";
import adminProps from "./_adminProps";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import "./SidebarLayout.less";
import { useAuth } from "../../../@hooks/useAuth";
import { LogoutLink } from "../../components/LogoutLink";

const menu = (
    <Menu>
        <Menu.Item key="profile">
            <NavLink to="/profile">Profile</NavLink>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
            <LogoutLink>Logout</LogoutLink>
        </Menu.Item>
    </Menu>
);

const adminMenu = (
    <Menu>
        <Menu.Item key="logout">
            <LogoutLink>Logout</LogoutLink>
        </Menu.Item>
    </Menu>
);

const SidebarLayout = (props) => {
    const settings = { fixSiderbar: true };
    const location = useLocation();
    const auth = useAuth();
    return (
        <div
            id="sidebar-layout"
            style={{
                height: "100vh",
            }}
        >
            <ProLayout
                {...(auth.admin?adminProps:defaultProps)}
                fixedHeader
                logo={<span className="uv-icon-unique-tour-logo"></span>}
                title="Unique VTour"
                menuHeaderRender={(logo, title) => {
                    return (
                        <div className="sidebar-layout-header">
                            {logo}
                            {auth.admin 
                                ? 
                                <h1>Client Portal <Tag>Admin</Tag></h1>
                                    
                                : <h1>Client Portal</h1>
                              }
                            
                        </div>
                    );
                }}
                location={{ pathname: location.pathname }}
                menuItemRender={(item, dom) => {
                    if (item.path === "/logout" && item.component) {
                        return React.createElement(item.component, { children: dom });
                    } else {
                        return <NavLink to={item.path}>{dom}</NavLink>;
                    }
                }}
                rightContentRender={() => (
                    <div>
                        <Dropdown overlay={auth.admin?adminMenu:menu} trigger={["click"]}>
                            <a href="" onClick={(e) => e.preventDefault()}>
                                <span>{auth.user.name}</span>&nbsp;
                                <Avatar shape="circle" style={{ color: "#f56a00", backgroundColor: "#fde3cf" }} icon={<UserOutlined />}></Avatar>
                            </a>
                        </Dropdown>
                    </div>
                )}
                footerRender={()=>{
                    return <DefaultFooter links={false} copyright={`Unique Vtour ${new Date().getFullYear()}`}></DefaultFooter>
                }}
                {...settings}
            >
                {props.children}
                
            </ProLayout>
            
        </div>
    );
};

export default SidebarLayout;