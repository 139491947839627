import ProForm, { ProFormGroup, ProFormText } from "@ant-design/pro-form";
import { PageContainer } from "@ant-design/pro-layout";
import { Alert, Divider, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../@hooks/useAuth";
import { LockTwoTone } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import MaskedInput from 'antd-mask-input';
import * as AuthService from "../../services/authService";
import { Errors } from "../../@theme/components/Errors";

const ChangePasswordForm = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    function changePasswordHandler (values) {
        setError(null);
        setSuccess(null);
        return AuthService.changePassword(values.currentPassword, values.password)
            .then(resp=>{
                form.resetFields();
                setSuccess(true);
                return true;
            })
            .catch(err=>{
                setError(err);
                return false;
            });
    }

    return (
        <ProForm form={form} layout="vertical" onFinish={changePasswordHandler} submitter={{ searchConfig: { submitText: "Save", resetText: "Reset" } }} validateMessages={{ required: "'${label}' is Required!" }}>
            
            <ProFormGroup title="Change password" direction="vertical" size={5}>
                {error && (
                    <React.Fragment>
                        <Errors error={error} />
                    </React.Fragment>
                )}
                {success === true && (
                    <React.Fragment>
                        <Alert type="success" showIcon message="Password updated successfully." />
                    </React.Fragment>
                )}
                <ProFormText fieldProps={{
                        size: "large",
                        prefix: <LockTwoTone />,
                        type: "password",
                        maxLength: 20,
                        minLength: 8,
                    }}
                    label="Current password"
                    name="currentPassword"
                    placeholder="Enter your current password."
                    rules={[
                        {
                            required: true,
                            message: "Enter your current password.",
                        },
                        {
                            pattern: /^\S{8,20}$/,
                            message: "Your new password should be minimum 8 characters without whitespaces.",
                        },
                    ]}
                    width="md"
                />
                <ProFormText
                    fieldProps={{
                        size: "large",
                        prefix: <LockTwoTone />,
                        type: "password",
                        maxLength: 20,
                        minLength: 8,
                    }}
                    label="New password"
                    name="password"
                    placeholder="Enter your new password."
                    tooltip="Min 8 characters, Max 20. No whitespaces."
                    rules={[
                        {
                            required: true,
                            message: "Enter your new password.",
                        },
                        {
                            pattern: /^\S{8,20}$/,
                            message: "Your new password should be minimum 8 characters without whitespaces.",
                        },
                    ]}
                    width="md"
                />

                <ProFormText
                    fieldProps={{
                        size: "large",
                        prefix: <LockTwoTone />,
                        type: "password",
                        maxLength: 20,
                        minLength: 8,
                    }}
                    label="Confirm password"
                    name="confirmPassword"
                    placeholder="Confirm your new password."
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Confirm your new password.",
                        },

                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("The two passwords that you entered do not match!"));
                            },
                        }),
                    ]}
                    width="md"
                />
            </ProFormGroup>
            <Divider/>
        </ProForm>
    )
}

const UpdateProfileForm = ({profile, auth}) => {
    const [form] = Form.useForm();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    function updateProfileHandler (values) {
        setError(null);
        setSuccess(null);
        return auth.updateProfile(values)
            .then(resp=>{
                form.resetFields();
                setSuccess(true);
                return true;
            })
            .catch(err=>{
                setError(err);
                return false;
            });
    }

    return (
        <ProForm form={form} onFinish={updateProfileHandler} layout="vertical" submitter={{ searchConfig: { submitText: "Save", resetText: "Reset" } }} initialValues={profile} validateMessages={{ required: "'${label}' is Required!" }}>
            <ProFormGroup title="Update Profile" direction="vertical" size={5}>
                {error && (
                    <React.Fragment>
                        <Errors error={error} />
                    </React.Fragment>
                )}
                {success === true && (
                    <React.Fragment>
                        <Alert type="success" showIcon message="Profile updated successfully." />
                    </React.Fragment>
                )}
                <ProFormText fieldProps={{size:"large", maxLength:40,minLength:3}}label="First Name" name="name" width="md" requiredMark required placeholder="Enter First Name" rules={[{ required: true }]} />
                <ProFormText fieldProps={{size:"large", maxLength:40,minLength:3}} label="Last Name" name="surname" width="md" requiredMark required placeholder="Enter Last Name" rules={[{ required: true }]} />
                <ProFormText label="Email" name="email" requiredMark required readonly />
                <ProForm.Item name="phone" label="Phone Number" rules={[{ required: true }]} required requiredMark >
                <MaskedInput  mask="(111) 111-1111" size="large"  placeholder="Contact Phone Number"  />
                </ProForm.Item>
            </ProFormGroup>
            <Divider/>
        </ProForm>
    )
}

const ProfilePage = () => {
    const auth = useAuth();
    let profile = auth.profile;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!profile) {
            setLoading(true);
            auth.getProfile().finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    const breakPoint = useBreakpoint();
    
    const tabPos=breakPoint.lg?'left':'top';

    return (
        <PageContainer loading={loading}>
            <ProCard
                tabs={{
                    tabPosition:tabPos,
                }}
            >
                <ProCard.TabPane key="tab-profile" tab="Update Profile" >
                    <UpdateProfileForm profile={profile} auth={auth} />
                </ProCard.TabPane>
                <ProCard.TabPane key="tab-password" tab="Change Password">
                    <ChangePasswordForm/>
                </ProCard.TabPane>
            </ProCard>
        </PageContainer>
    );
};

export { ProfilePage };
