import { Table } from "antd";

const columns = [
    {
        title: 'Options',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Price',
        dataIndex: 'price.amount',
        key: 'price',
        //   render:(record)=>{
        //       return `${(record.price.amount/100)}`;
        //   }
    }
];

function ItemOptions({ itemWithOptions }) {
    return (
        <Table dataSource={itemWithOptions.variations} columns={
            columns
        }>
        </Table>
    )
}

export { ItemOptions }