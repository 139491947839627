import React, { useEffect, useState } from "react";
import { Button, Result, Skeleton } from "antd";
import * as AuthService from "../../services/authService";
import { SimpleLayout } from "../../@theme/layout/auth/SimpleLayout";
import { useQuery } from "../../@hooks/useQuery";
import { Link } from "react-router-dom";

function ActivationPageComponent() {
    const [activationResult, setActivationResult] = useState(null);
    let query = useQuery();
    const code = query.get("code");

    useEffect(() => {
        if (code) {
            AuthService.activate(code)
                .then(() => {
                    setActivationResult(true);
                })
                .catch(() => {
                    setActivationResult(false);
                });
        }
    }, [code]);
    return (
        <SimpleLayout pageTitle="Confirm Email address">
            {code && activationResult === null && <Skeleton loading active></Skeleton>}
            {code && activationResult === true && (
                <Result status="success" title="Email address is confirmed!">
                    <Link to="/login">
                        <Button type="primary" block size="large">
                            Login now
                        </Button>
                    </Link>
                </Result>
            )}
            {(!code || activationResult === false) && <Result status="error" title="Invalid confirmation link."></Result>}
        </SimpleLayout>
    );
}

export { ActivationPageComponent as ActivationPage };
