import React, { useState } from "react";
import { Button, Result, Space } from "antd";
import * as AuthService from "../../services/authService";
import { SimpleLayout } from "../../@theme/layout/auth/SimpleLayout";
import { Link } from "react-router-dom";
import ProForm, { ProFormText } from "@ant-design/pro-form";
import { CodeTwoTone, LockTwoTone } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { useQuery } from "../../@hooks/useQuery";
import { Errors } from "../../@theme/components/Errors";

function ResetPasswordPageComponent() {
    const [resetPasswordResult, setResetPasswordResult] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    let query = useQuery();
    const code = query.get("code");

    return (
        <SimpleLayout pageTitle="Reset password">
            <Space direction="vertical" style={{ width: "100%" }}>
                {resetPasswordResult === true && (
                    <Result status="success" title="Your password has been updated, please login now.">
                        <Link to="/login">
                            <Button type="primary" block size="large">
                                Login now
                            </Button>
                        </Link>
                    </Result>
                )}
                {resetPasswordResult !== true && (
                    <ProForm
                        onFinish={async (values) => {
                            setResetPasswordResult(null);
                            setError(null);
                            setLoading(true);
                            AuthService.resetPassword(values.activationCode, values.password)
                                .then(() => {
                                    setResetPasswordResult(true);
                                })
                                .catch((error) => {
                                    if (error && error.message) {
                                        setError(error);
                                    } else {
                                        setError({ message: "Unexpected error occurred, we were not able to change your password. You may try again later." });
                                    }
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                        submitter={{
                            searchConfig: {
                                submitText: "Submit",
                            },
                            render: (_, dom) => dom.pop(),
                            submitButtonProps: {
                                size: "large",
                                block: true,
                                loading: loading,
                            },
                        }}
                    >
                        <Space direction="vertical" style={{ width: "100%" }} size="small">
                            <Title style={{ textAlign: "center" }} level={3}>
                                {" "}
                                Reset password
                            </Title>
                            {error && (
                                <React.Fragment>
                                    <Errors error={error} />
                                </React.Fragment>
                            )}
                            <ProFormText
                                fieldProps={{
                                    size: "large",
                                    prefix: <CodeTwoTone />,
                                    type: "text",
                                }}
                                initialValue={code}
                                label="Activation Code"
                                name="activationCode"
                                placeholder="Enter reset password activation code"
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter reset password activation code.",
                                    },
                                ]}
                            />
                            <ProFormText
                                fieldProps={{
                                    size: "large",
                                    prefix: <LockTwoTone />,
                                    type: "password",
                                    maxLength: 20,
                                    minLength: 8,
                                }}
                                label="New password"
                                name="password"
                                placeholder="Enter your new password."
                                tooltip="Min 8 characters, Max 20. No whitespaces."
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter your new password.",
                                    },
                                    {
                                        pattern: /^\S{8,20}$/,
                                        message: "Your new password should be minimum 8 characters without whitespaces.",
                                    },
                                ]}
                            />

                            <ProFormText
                                fieldProps={{
                                    size: "large",
                                    prefix: <LockTwoTone />,
                                    type: "password",
                                    maxLength: 20,
                                    minLength: 8,
                                }}
                                label="Confirm password"
                                name="confirmPassword"
                                placeholder="Confirm your new password."
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Confirm your new password.",
                                    },

                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("The two passwords that you entered do not match!"));
                                        },
                                    }),
                                ]}
                            />
                        </Space>
                    </ProForm>
                )}
                <Link className="" to="/login">
                    <Button block type="link">
                        Back to login
                    </Button>
                </Link>
            </Space>
        </SimpleLayout>
    );
}

export { ResetPasswordPageComponent as ResetPasswordPage };
