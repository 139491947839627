import { DollarCircleOutlined, ProfileOutlined } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import * as UserBookingService from "../../services/userBookingService";
import { BookingStatus } from "./components/BookingStatus";
import { BookingSummaryCard } from "./components/BookingSummaryCard";
import { OrderSummaryCard } from "./components/OrderSummaryCard";
import { VtourSummaryCard } from "./components/VtourSummaryCard";

const breadcrumbDefault = {
    routes: [
        {
            path: "/my-bookings",
            breadcrumbName: "Bookings",
        },
        {
            path: "/my-bookings/:bookingId",
            breadcrumbName: `Details`,
        },
    ],
};

function BookingDetailsPageComponent(props) {
    
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState(null);
    const [breadcrumb, setBreadcrumb] = useState(breadcrumbDefault);
    
    let routeParams = useParams();

    function breadcrumbItemRender(route, params, routes, paths) {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? <span>{route.breadcrumbName}</span> : <Link to={`/${paths.join("/")}`}>{route.breadcrumbName}</Link>;
    }

    useEffect(() => {
        if (booking === null && routeParams.bookingId) {
            
            UserBookingService.get(routeParams.bookingId)
                .then((resp) => {
                    setBooking(resp.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        return () => void 0;
    }, [booking]);

    const bookingTitle = `Booking details: ${booking?.invoice?.invoiceNumber}`;
    const vtour = booking?.vtour;
    return (
        <PageContainer loading={loading} title={loading ? "Booking details" : (
                <React.Fragment>{bookingTitle}&nbsp;<BookingStatus status={booking?.status} />
                </React.Fragment>)} 
            breadcrumb={{ ...breadcrumb, itemRender: breadcrumbItemRender }}>
            <Helmet>
                <title>{bookingTitle} - UniqueVtour Client Portal</title>
            </Helmet>
            <ProCard ghost  direction="column">
            <BookingSummaryCard loading={loading} title={<React.Fragment><ProfileOutlined />&nbsp;Summary:</React.Fragment>} booking={booking} extraHidden showRemark />
            {vtour && <VtourSummaryCard style={{marginTop:'24px'}}  vtour={vtour}/>}
            <OrderSummaryCard style={{marginTop:'24px'}} loading={loading} title={<React.Fragment><DollarCircleOutlined />&nbsp;Order:</React.Fragment>} booking={booking} extraHidden />
            </ProCard>
        </PageContainer>
    );
}

export { BookingDetailsPageComponent as BookingDetailsPage };
