import axios from "axios";
import { decodeJwtPayload } from "../utils/authHelper";
import * as AuthService from "./authService";

const SESSION_KEY = 'univt_cp_session';

const getLocalStorage = (key, initialValue=null) => {
    const val = window.localStorage.getItem(key);
    if(val){
        try {
            return JSON.parse(val);
        } catch (error) {
            return val;
        }
    }
    return initialValue;
}

const setLocalStorage = (key, value) => {
    
    if(key){
        let valueStr = null;
        try {
            if(value)
                valueStr = JSON.stringify(value);
        } catch (error) {
             valueStr = value;
        }
        window.localStorage.setItem(key,valueStr);
    }
}

function createInstanceWithToken ({forAdminToken=false}){
    const isAdmin = forAdminToken === true;
    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use((request) => {
        const session = getLocalStorage(SESSION_KEY);
        if (session != null && session.token) {
            request.headers["Authorization"] = `Bearer ${session.token}`;
        }
        return request;
    });

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            const originalRequest = { ...error.config };
            const status = error.response ? error.response.status : null;
            if (status === 401) {
                const session = getLocalStorage(SESSION_KEY);
                if (session != null && session.token) {
                    const refreshPromise = forAdminToken? AuthService.refreshAdmin(session.token) : AuthService.refresh(session.token);
                    return refreshPromise
                        .then((resp) => {
                            const jwtPayload = decodeJwtPayload(resp.data.token);
                            session.exp = jwtPayload.exp;
                            session.token = resp.data.token;
                            setLocalStorage(SESSION_KEY,session);
                            return protectedAxiosInstance(originalRequest);
                        })
                        .catch((err) => {
                            setLocalStorage(SESSION_KEY,null);
                            window.location.href = `${(isAdmin?'/admin':'')}/login?return=${window.location.pathname}`;
                            return Promise.reject(err);
                        });
                } else {
                    setLocalStorage(SESSION_KEY,null);
                    window.location.href = `${(isAdmin?'/admin':'')}/login?return=${window.location.pathname}`;
                    return Promise.reject(error);
                }
            } else {
                if(error?.response?.data?.error && error?.response?.data?.error === 'token_not_provided' ){
                    setLocalStorage(SESSION_KEY,null);
                    window.location.href = `${(isAdmin?'/admin':'')}/login?return=${window.location.pathname}`;
                }
                return Promise.reject(error);
            }
        }
    );

    return axiosInstance;
}

const protectedAxiosInstance = createInstanceWithToken({});
const protectedAxiosInstanceForAdmin = createInstanceWithToken({forAdminToken:true});

export { protectedAxiosInstance as axiosWithToken, protectedAxiosInstanceForAdmin as axiosWithAdminToken };
