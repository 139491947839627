import axios from "axios";
import fetch from "unfetch";
import { axiosWithToken } from "./protectedServiceProvider";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL||'https://uniquevtour.com/api/v1';

async function getShoppingCartSummary(shoppingCartItems) {
    return await fetch(
        `${apiBaseUrl}/bookings/shoppingCart/summary`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ lineItems: shoppingCartItems }),
        }
    ).then((resp) => resp.json());
}

async function createOrder(shoppingCartItems, bookingDetails) {
    const req = {
        items: [...shoppingCartItems],
        property: { ...bookingDetails.property },
        contact: { ...bookingDetails.contact },
    };
    req.contact.phone = req.contact.phone.replace(/\D/g, "");
    return await axios.post(
        `${apiBaseUrl}/bookings`,
        req
    );
}

async function createUserOrder(shoppingCartItems, bookingDetails) {
    const req = {
        items: [...shoppingCartItems],
        property: { ...bookingDetails.property },
        contact: { ...bookingDetails.contact },
    };
    req.contact.phone = req.contact.phone.replace(/\D/g, "");
    return await axiosWithToken.post(
        `${apiBaseUrl}/bookings/mine`,
        req
    );
}

async function createUserOrderByAdmin(shoppingCartItems, bookingDetails) {
    const req = {
        items: [...shoppingCartItems],
        property: { ...bookingDetails.property },
        contact: bookingDetails.contact?.newUser ? 
            { ...bookingDetails.contact.newUser }
            : { userId: bookingDetails.contact?.userId?.value },
    };
    if (req.contact.phone) {
        req.contact.phone = req.contact.phone.replace(/\D/g, "");
    }
    return await axiosWithToken.post(
        `${apiBaseUrl}/bookings/user`,
        req
    );
}

export { getShoppingCartSummary, createOrder, createUserOrder, createUserOrderByAdmin };
