import { Descriptions, List } from "antd";

import DescriptionsItem from "antd/lib/descriptions/Item";

import Text from "antd/lib/typography/Text";
import React from "react";
import ProCard from '@ant-design/pro-card';
import { formatCurrency } from "../../../utils/format";
import { BookingStatus } from "./BookingStatus";
import { EyeOutlined } from "@ant-design/icons";

const OrderSummaryCard = ({ loading = false, title, booking, extraHidden = false, bordered=false, style }) => {
    const currentStep = booking ? booking.current_step || 0 : 0;
    const { invoice, order } = booking || {};
    const paymentRequest = invoice?.paymentRequests ? invoice?.paymentRequests[0] : null;
    if (booking) {
        return (
            <ProCard bordered={bordered}
                loading={loading}
                headerBordered
                title={title}
                style={style}
            >
                {currentStep > 1 && (
                    <Descriptions>
                        <DescriptionsItem label="Reference #">
                            <Text strong underline>
                                {invoice.invoiceNumber}
                            </Text>
                        </DescriptionsItem>
                    </Descriptions>
                )}

                <Descriptions layout="vertical" labelStyle={{color:"#8c8c8c"}} contentStyle={{fontSize:"1.25rem", marginBottom:"15px"}}>
                    <DescriptionsItem label="Status"><Text strong>{<BookingStatus large status={invoice.status}/>}</Text>
                    </DescriptionsItem>
                    {paymentRequest.total?.amount >0 && 
                        <DescriptionsItem label="Estimated total (HST included)">
                        <Text strong>{formatCurrency(paymentRequest.total?.amount)}</Text>
                        </DescriptionsItem>
                    }
                    
                        <DescriptionsItem label="Invoice">
                        {invoice.invoiceUrl ? 
                        <a rel="noreferrer" target="_blank" href={invoice.invoiceUrl}><EyeOutlined/>&nbsp;View Invoice</a>
                        : "Not ready yet."
                        }
                        </DescriptionsItem>
                    
                    
                </Descriptions>

                {order && (
                    <List
                    header="Items"
                        dataSource={order.lineItems}
                        renderItem={(item) => (
                            <List.Item key={item.id}>
                                <List.Item.Meta title={item.name} />
                            </List.Item>
                        )}
                    ></List>
                )}
            </ProCard>
        );
    }

    return <ProCard loading={loading}></ProCard>;
};

export { OrderSummaryCard };
