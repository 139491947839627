
import './Header.less';
import { Badge, Button, Col, Layout, Menu, Row, Tag } from "antd";
import { withRouter,Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { MenuFoldOutlined, MenuUnfoldOutlined, ShoppingTwoTone } from '@ant-design/icons';
import { useSelector } from "react-redux";
import React, { useState } from 'react';
import { useAuth } from '../../../@hooks/useAuth';

const { Header: HeaderComponent } = Layout;

function Header({ location, onShoppingCartBtnClick }) {

  const selectedHash = location.hash || '';
  const shoppingCartItems = useSelector(state => state.shoppingCart.items);
  const [sideMenuCollapsed, setSideMenuCollapsed]=useState(true);

  const auth = useAuth();

  return (
    <React.Fragment>
    <Menu className="side-menu" theme="" selectedKeys={[selectedHash.replace('#', '')]} inlineCollapsed={sideMenuCollapsed} mode="inline">
            {auth.user && <Menu.Item key="portal"><Link to={auth.admin?"/admin":"/"}>Back to Portal</Link></Menu.Item>}
            <Menu.Item key="packages"><NavHashLink to="#packages" onClick={()=>setSideMenuCollapsed(true)} >Packages</NavHashLink></Menu.Item>
            <Menu.Item key="add-ons"><NavHashLink to="#add-ons"  onClick={()=>setSideMenuCollapsed(true)}>Add-ons</NavHashLink></Menu.Item>
            <Menu.Item key="flyer"><NavHashLink to="#flyer"  onClick={()=>setSideMenuCollapsed(true)}>Flyer/Brochure/Booklet</NavHashLink></Menu.Item>
            <Menu.Item key="serviceAreas"><NavHashLink to="#serviceAreas"  onClick={()=>setSideMenuCollapsed(true)}>Service Areas</NavHashLink></Menu.Item>
            <Menu.Item key="shopping-cart" onClick={onShoppingCartBtnClick}>
                
                <Badge offset={[5, 0]} count={shoppingCartItems.length}><ShoppingTwoTone />
                  
                  &nbsp;Cart
                  </Badge>
            </Menu.Item>
          </Menu>
    <HeaderComponent className="header">
      <Button type="text" className="btn-side-menu-trigger" onClick={(e)=>setSideMenuCollapsed(!sideMenuCollapsed)} >
          {React.createElement(sideMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
        </Button>
      <div className="logo">
        <img src="/unique-tour-logo.png" alt="Unique Vtour Logo" />{auth.admin?<React.Fragment>&nbsp;<Tag style={{fontSize:'1rem', verticalAlign:'super'}} color="blue">Admin</Tag></React.Fragment>:null}
      </div>
      <Row>
        <Col>
          <Menu className="menu-row" theme="" mode="horizontal" selectedKeys={[selectedHash.replace('#', '')]}>
            {auth.user && <Menu.Item key="portal"><Link to={auth.admin?"/admin":"/"}>Back to Portal</Link></Menu.Item>}
            <Menu.Item key="packages"><NavHashLink to="#packages">Packages</NavHashLink></Menu.Item>
            <Menu.Item key="add-ons"><NavHashLink to="#add-ons">Add-ons</NavHashLink></Menu.Item>
            <Menu.Item key="flyer"><NavHashLink to="#flyer">Flyer/Brochure/Booklet</NavHashLink></Menu.Item>
            <Menu.Item key="serviceAreas"><NavHashLink to="#serviceAreas">Service Areas</NavHashLink></Menu.Item>
          </Menu>


        </Col>
        <Col>

          <Button className="btn-shopping-cart" onClick={onShoppingCartBtnClick} type="link" size="large" >
            <Badge offset={[5, 0]} count={shoppingCartItems.length}><ShoppingTwoTone />
            
            &nbsp;Cart
            </Badge>
          </Button>
        </Col>
        <Button className="side-btn-shopping-cart" onClick={onShoppingCartBtnClick} type="link" size="large" >
            <Badge offset={[5, 0]} count={shoppingCartItems.length}><ShoppingTwoTone />
            </Badge>
          </Button>
      </Row>

    </HeaderComponent>
    </React.Fragment>
  )
}


const HeaderComp = withRouter(Header);

export { HeaderComp as Header }