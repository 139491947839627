import { axiosWithAdminToken } from "./protectedServiceProvider";
import { handleServerError } from "./errorHandler";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL||'https://uniquevtour.com/api/v1';

const searchUsers = (term) => {
    return axiosWithAdminToken.get(`${apiBaseUrl}/admin/users/simpleList`,{
        params: {
            term
        }
    }).catch((error) => {
        return handleServerError(error, "We are currently not able to find any matched users.");
    });
};

export { searchUsers };
