import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatCurrency(price, miniorUnits = 2) {
    return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD', minimumFractionDigits: 2 }).format(price / Math.pow(10, miniorUnits));
}

export function formatTimestamp({timestamp, tz = 'America/Toronto', format = 'MMMM D, YYYY h:mm A'}) {
    return dayjs.utc(timestamp).tz(tz).format(format);
}