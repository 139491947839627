import { Layout } from "antd";
import { Header } from "./Header";
import './BookingsLayout.less';
import { Helmet } from "react-helmet";

const { Content, Footer } = Layout;

function BookingsLayout({ pageTitle, helmetChildren, children, onShoppingCartBtnClick }) {
  return (
    <Layout className="bookings-layout" style={{ minHeight: '100vh' }}>
      <Helmet>
          <title>{pageTitle||"Bookings"} - UniqueVtour Client Portal</title>
          {helmetChildren}
      </Helmet>
      <Header onShoppingCartBtnClick={onShoppingCartBtnClick}></Header>
      <Content className="site-layout">

        <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Unique Vtour ©{new Date().getFullYear()}</Footer>
    </Layout>)
}

export { BookingsLayout };