import { Alert } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

function Errors({ error }) {
    const errorsList = error.errors;
    const errorMessage = error.message || "Oops, error occurred.";
    return (
        <Alert
            message={errorMessage}
            description={
                errorsList && (
                    <Paragraph>
                        <ul>
                            {Object.values(errorsList).map((fieldErrors, idx) => (
                                <li key={idx}>{fieldErrors[0]}</li>
                            ))}
                        </ul>
                    </Paragraph>
                )
            }
            type="error"
            showIcon
        />
    );
}

export { Errors };
