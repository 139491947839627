import { DeleteOutlined, InfoCircleOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Divider, Row, Select, Skeleton, Space, Statistic, Tooltip } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/format";
import './CategoryItem.less';

const { Option } = Select;

function ItemDetail({ item }) {
    let itemDescription = null;
    if(item.attributes.description && item.attributes.description.indexOf(';')>-1){
        const descriptionList = item.attributes.description.split(';').map((item, idx)=>{ return <li key={idx}>{item}</li>});
        itemDescription = <div>{item.name} <ul>{descriptionList}</ul></div>;
    }else{
        itemDescription = <div>{item.name} {item.attributes.description && <Tooltip title={item.attributes.description}><InfoCircleOutlined /></Tooltip>}</div>;
    }
    

    return (
        <Statistic title={<Alert type="info" message={itemDescription} />} value={formatCurrency(item.price.amount)} precision={2} />
    );
}

function ItemCol({ item, checkSelected, onClick, onSelectedChange }) {
    const [selectedOption, setSelectedOption] = useState();
    let isItemInShoppingCart = checkSelected && checkSelected(selectedOption);
    const handleOptionChange = (selected) => {
        onSelectedChange && onSelectedChange({ selected, lastSelected: selectedOption });
        setSelectedOption(selected);
    }
    return (
        <Col md={8} lg={6} sm={24} xs={24}>
            <Card className="category-item-card" hoverable>
                <h2>{item.name}</h2>
                <Select value={selectedOption ? selectedOption.id : null} allowClear placeholder="select an option" onChange={(id) => {
                    const selected = item.variations.find(v => v.id === id);
                    handleOptionChange(selected);
                }}>
                    {item.variations.map((v) => {
                        return (<Option value={v.id} key={v.id}>
                            {v.name} - {formatCurrency(v.price.amount)}
                        </Option>)
                    })}
                </Select>
                {selectedOption && <div className="category-item-selected">
                    <Divider plain />
                    <Space direction="vertical">
                        <ItemDetail item={selectedOption} />
                        {isItemInShoppingCart ?
                            <Button danger block onClick={(e) => handleOptionChange(null)} ><DeleteOutlined /> Delete </Button>
                            : <Button ghost={isItemInShoppingCart} disabled={isItemInShoppingCart} onClick={(e) => { if (onClick) { onClick({ event: e, item: { ...selectedOption } }) } }} block type="primary">
                                <ShoppingCartOutlined /> Add
                    </Button>}
                    </Space>
                </div>}
            </Card>
        </Col>
    )
}

function Loading() {
    return (<Row gutter={[20, 20]}>
        <Col md={6} sm={24} xs={24}>
            <Card bordered={false}><Skeleton loading={true} /></Card>
        </Col>
        <Col md={6} sm={24} xs={24}>
            <Card bordered={false}><Skeleton loading={true} /></Card>
        </Col>
        <Col md={6} sm={24} xs={24}>
            <Card bordered={false}><Skeleton loading={true} /></Card>
        </Col>
        <Col md={6} sm={24} xs={24}>
            <Card bordered={false}><Skeleton loading={true} /></Card>
        </Col>
    </Row>)
}

function CategoryItems({ loading, categoryItems, onClick, onItemOptionChange }) {
    const shoppingCartItems = useSelector(state => state.shoppingCart.items);

    if (loading) {
        return <Loading />
    }

    if (!categoryItems) return null;
    return (
        <Row gutter={[20, 20]}>
            { categoryItems.items.map((item, idx) =>
                <ItemCol checkSelected={(selectedItem) => selectedItem && shoppingCartItems.findIndex(i => i.id === selectedItem.id) > -1}
                    onClick={onClick} onSelectedChange={onItemOptionChange} item={item} key={item.id} />)}

        </Row>
    );
}

export { CategoryItems }