import { axiosWithToken } from "./protectedServiceProvider";
import { handleServerError } from "./errorHandler";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL||'https://uniquevtour.com/api/v1';

const getLatest = () => {
    return axiosWithToken.get(`${apiBaseUrl}/bookings/mine/latest`).catch((error) => {
        return handleServerError(error, "We are currently not able to find your latest booking history.");
    });
};

const get = (bookingId) => {
    return axiosWithToken.get(`${apiBaseUrl}/bookings/mine/history/${bookingId}`).catch((error) => {
        return handleServerError(error, "We are currently not able to find requested booking history.");
    });
};

const getHistory = (page, perPage) => {
    return axiosWithToken
        .get(`${apiBaseUrl}/bookings/mine/history`, {
            params: {
                page,
                perPage,
            },
        })
        .catch((error) => {
            return handleServerError(error, "We are currently not able to find your booking history.");
        });
};

export { getLatest, getHistory, get };
