import { FormOutlined, ProfileOutlined } from "@ant-design/icons";
import ProList from "@ant-design/pro-list";
import { PageContainer } from "@ant-design/pro-layout";
import { Button } from "antd";

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as UserBookingService from "../../services/userBookingService";
import { BookingSummaryCard } from "./components/BookingSummaryCard";

import "./BookingsListPage.less";
import enUS from "antd/lib/locale/en_US";
import { enUSIntl, ConfigProvider as ProConfigProvider } from "@ant-design/pro-provider";
import { BookingStatus } from "./components/BookingStatus";

const breadcrumbDefault = {
    routes: [
        {
            path: "/my-bookings",
            breadcrumbName: "Bookings",
        },
    ],
};

function BookingsListPageComponent(props) {
    const [breadcrumb, setBreadcrumb] = useState(breadcrumbDefault);
    
    function breadcrumbItemRender(route, params, routes, paths) {
        const last = routes.indexOf(route) === routes.length - 1;
        
        return last ? <span>{route.breadcrumbName}</span> : <Link to={`/${paths.join("/")}`}>{route.breadcrumbName}</Link>;
    }

    const bookingTitle = `Booking History:`;
    return (
        <PageContainer title={bookingTitle} breadcrumb={{ ...breadcrumb, itemRender: breadcrumbItemRender }}>
            <Helmet>
                <title>{bookingTitle} - UniqueVtour Client Portal</title>
            </Helmet>

            <ProConfigProvider value={{ intl: enUSIntl }}>
                <ProList
                    toolBarRender={false}
                    showHeader={false}
                    className="bookings-list-container"
                    request={(params, sort, filter) => {
                        
                        return UserBookingService.getHistory(params.current, params.pageSize).then((resp) => {
                            const result = resp.data;
                            return {
                                data: result.data,
                                page: result.current_page,
                                total: result.total,
                                success: true,
                            };
                        });
                    }}
                    rowKey="id"
                    pagination={{
                        pageSizeOptions: ["5", "10", "20"],
                        responsive: true,
                        pageSize: 5,
                        locale: enUS.Pagination,
                    }}
                    toolBarRender={() => {
                        return [
                            <Link to="/bookings">
                                <Button block type="primary" size="large" icon={<FormOutlined />}>
                                    New booking
                                </Button>
                            </Link>
                        ];
                      }}
                    renderItem={(booking) => {
                        return (
                            <React.Fragment>
                                <BookingSummaryCard
                                    hoverable
                                    key={booking.id}
                                    title={
                                        <React.Fragment>
                                            <ProfileOutlined />
                                            &nbsp;Booking #{booking.invoice_number}
                                            &nbsp;<BookingStatus status={booking.status} />
                                        </React.Fragment>
                                    }
                                    booking={booking}
                                    stepsHidden
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </ProConfigProvider>
        </PageContainer>
    );
}

export { BookingsListPageComponent as BookingsListPage };
