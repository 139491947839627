import { FormOutlined, UnorderedListOutlined } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { Button, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../@hooks/useAuth";
import * as UserBookingService from "../../services/userBookingService";
import { BookingStatus } from "./components/BookingStatus";
import { BookingSummaryCard } from "./components/BookingSummaryCard";

const ContainerContent = ({ user, loading, bookingDetails }) => {
    return (
        <React.Fragment>
            <Typography>
                <Title>Hi, {user.name}!</Title>
                <Paragraph>Welcome to Unique Vtour Client Portal.</Paragraph>
            </Typography>

            <BookingSummaryCard hoverable bordered loading={loading} title={<React.Fragment>Your latest booking:&nbsp;<BookingStatus status={bookingDetails?.status}/></React.Fragment>} booking={bookingDetails} />
        </React.Fragment>
    );
};

function WelcomePageComponent(props) {
    let auth = useAuth();
    const [loading, setLoading] = useState(true);
    const [latestBooking, setLatestBooking] = useState(null);

    useEffect(() => {
        if (latestBooking === null) {
            UserBookingService.getLatest()
                .then((resp) => {
                    setLatestBooking(resp.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        return () => void 0;
    }, [latestBooking]);

    return (
        <PageContainer loading={loading} content={<ContainerContent key="content" user={auth.user} bookingDetails={latestBooking} loading={loading} />}>
            
            <ProCard key={0} ghost wrap gutter={[24,30]}>
                <ProCard key="booking-history-card" ghost hoverable colSpan={{ sm: 24, md: 12 }}>
                    <Link to="/my-bookings">
                        <Button block color="#8c8c8c" size="large" style={{ height: "100px" }} icon={<UnorderedListOutlined />}>
                            Bookings History
                        </Button>
                    </Link>
                </ProCard>

                <ProCard key="create-booking-card" ghost hoverable colSpan={{ sm: 24, md: 12 }}>
                    <Link to="/bookings">
                        <Button block type="primary" size="large" style={{ height: "100px" }} icon={<FormOutlined />}>
                            Create booking
                        </Button>
                    </Link>
                </ProCard>
            </ProCard>
            
        </PageContainer>
    );
}

export { WelcomePageComponent as WelcomePage };
