import React, { useState, useEffect, useContext, createContext } from "react";
import { login, logout, getProfile as getUserProfile, updateProfile as updateUserProfile, loginWithUsername } from "../services/authService";
import { decodeJwtPayload } from "../utils/authHelper";
import { useLocalStorage, getLocalStorage } from "./useLocalStorage";

const authContext = createContext();
const SESSION_KEY = 'univt_cp_session';

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [profile, setProfile] = useState(null);
    const [session, setSession] = useLocalStorage(SESSION_KEY, null);
    
    if (session != null && session.token) {

        if((session.exp + 86400) < Math.floor(Date.now() / 1000)){
            setSession(null);
            setUser(null);
            setProfile(null);
        }
        else {
            if (session.exp && !user) {
                const jwtPayload = decodeJwtPayload(session.token);
                setAdmin(jwtPayload.admin === true);
                setUser(session.user);
            }
        }
    }
    
    const signin = ({username, email, password}) => {
        const loginPromise = email? login(email, password):loginWithUsername(username, password);
        return loginPromise.then(resp => {
            const jwtPayload = decodeJwtPayload(resp.data.token);
            let userResp = { ...resp.data };
            userResp.exp = jwtPayload.exp;
            setSession(userResp);   
            setUser(userResp.user);
            setAdmin(jwtPayload.admin === true);
            setProfile(null);
            return userResp.user;
        });
    };

    const signout = () => {
        if(session?.token){
            return logout(session.token).then(()=>{
                setSession(null);   
                setUser(null);
                setProfile(null);
                return true;
            });
        }
        return Promise.reject(false);
    };

    const getProfile = () => {
        if(profile === null){
            return getUserProfile()
            .then(resp => {
                if(resp.data?.user){
                    const userProfile = {...resp.data.user};
                    setProfile(userProfile);
                    return userProfile;
                }
                return false;
            });
        }

        return Promise.resolve(profile);
    };

    const updateProfile = (profileUpdate) => {
        
        return updateUserProfile(profileUpdate)
        .then(resp => {
            const userProfile = {...profileUpdate};
            const currentSession = getLocalStorage(SESSION_KEY);
            if(currentSession){
                currentSession.user = userProfile;
                setSession(currentSession);
            }
            setUser(userProfile);
            setProfile(userProfile);
            return true;    
        });
    };

    const clearProfileCache = () => {
        setProfile(null);
    }

    const sendPasswordResetEmail = email => {
        // return firebase
        //     .auth()
        //     .sendPasswordResetEmail(email)
        //     .then(() => {
        //         return true;
        //     });
    };

    const confirmPasswordReset = (code, password) => {
        // return firebase
        //     .auth()
        //     .confirmPasswordReset(code, password)
        //     .then(() => {
        //         return true;
        //     });
    };

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = () => { };
        // firebase.auth().onAuthStateChanged(user => {
        //     if (user) {
        //         setUser(user);
        //     } else {
        //         setUser(false);
        //     }
        // });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    // Return the user object and auth methods
    return {
        admin,
        user,
        profile,
        clearProfileCache,
        signin,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        getProfile,
        updateProfile
    };
}