import { CameraOutlined } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card"
import { Alert, Image, List, Typography } from "antd"
import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";

const VtourSummaryCard = ({vtour, style}) => {
    return (
        <ProCard style={style} wrap direction="row" split="vertical" title={<React.Fragment><CameraOutlined/>&nbsp;Virtual Tour:</React.Fragment>} headerBordered>
            <ProCard key="image" colSpan={{ sm: 24, md: 8 }} layout="center">
                <Image preview={false} src={vtour?.cover_thumbnail}></Image>
            </ProCard>
            <ProCard key="vtour-links" colSpan={{ sm: 24, md: 16  }}>
            <List header={<Typography.Title level={4}>Virtual Tour Links:</Typography.Title>}>
                <Alert showIcon type="warning" message="Please note that：Branded virtual tour is NOT for MLS, and it is for realtor and other business estate websites." />                
                <List.Item> <Paragraph copyable={{text:`${vtour.slug_url}?mode=download`, tooltips:"Click here to copy url"}}><a rel="noreferrer" href={`${vtour.slug_url}?mode=download`} target="_blank">Download Resources</a> Or copy the url</Paragraph></List.Item>
                <List.Item> <Paragraph copyable={{text:vtour.slug_url, tooltips:"Click here to copy url"}}><a rel="noreferrer" href={vtour.slug_url} target="_blank">View Unbranded Virtual Tour (<Typography.Text type="danger" style={{fontWeight:'bold'}}>MLS Only</Typography.Text>)</a> Or copy the url</Paragraph></List.Item>
                {vtour.matter_port_url && 
                    <List.Item> <Paragraph copyable={{text:vtour.matter_port_url, tooltips:"Click here to copy url"}}><a rel="noreferrer" href={vtour.matter_port_url} target="_blank">View 3D link </a> Or copy the url</Paragraph></List.Item>
                }
                {vtour.youtube_url && 
                    <List.Item> <Paragraph copyable={{text:vtour.youtube_url, tooltips:"Click here to copy url"}}><a rel="noreferrer" href={vtour.youtube_url} target="_blank">View YouTube link </a> Or copy the url</Paragraph></List.Item>
                }
                <List.Item> <Paragraph copyable={{text:vtour.slug_url, tooltips:"Click here to copy url"}}><a rel="noreferrer" href={`${vtour.slug_url}?mode=contact`} target="_blank">View Branded Virtual Tour </a> Or copy the url</Paragraph>
                </List.Item>
                
            </List>
            </ProCard>
        </ProCard>
    )
}

export {VtourSummaryCard};