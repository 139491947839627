import axios from "axios";
import { handleServerError } from "./errorHandler";
import { axiosWithToken } from "./protectedServiceProvider";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL||'https://uniquevtour.com/api/v1';

const login = (email, password) => {
    return axios
        .post(`${apiBaseUrl}/auth/login`, {
            email,
            password,
        })
        .then((resp) => {
            if (resp.data && resp.data.user) {
                if (resp.data.user.is_activated) {
                    return resp;
                } else {
                    return Promise.reject({
                        message:
                            "Your email address is not confirmed yet, please check your email inbox.",
                    });
                }
            }
        })
        .catch((error) => {
            return handleServerError(
                error,
                "Incorrect username or password. If you are a newly registered customer, please ensure to confirm your account.",
                "Unexpected login error ocurred."
            );
        });
};

const loginWithUsername = (username, password) => {
    return axios
        .post(`${apiBaseUrl}/auth/admin/login`, {
            login: username,
            password,
        })
        .then((resp) => {
            if (resp.data && resp.data.user) {
                return resp;
            }
        })
        .catch((error) => {
            return handleServerError(
                error,
                "Incorrect username or password.",
                "Unexpected login error ocurred."
            );
        });
};

const activate = (code) => {
    const activationUrl = `${apiBaseUrl}/auth/activate?code=${code}`;
    return axios.get(activationUrl).catch((error) => {
        return handleServerError(error, "Unexpected error ocurred.");
    });
};

const restorePassword = (email) => {
    const restoreUrl = `${apiBaseUrl}/auth/restore`;
    return axios
        .post(restoreUrl, {
            email,
        })
        .catch((error) => {
            return handleServerError(error);
        });
};

const resetPassword = (code, password) => {
    const resetUrl = `${apiBaseUrl}/auth/reset`;
    return axios
        .post(resetUrl, {
            code,
            password,
        })
        .catch((error) => {
            return handleServerError(error);
        });
};

const changePassword = (currentPassword, password) => {
    const changePasswordUrl = `${apiBaseUrl}/auth/me/password`;
    return axiosWithToken
        .patch(changePasswordUrl, {
            currentPassword,
            password
        })
        .catch((error) => {
            return handleServerError(error);
        })
};

const logout = (token) => {
    const logoutUrl = `${apiBaseUrl}/auth/invalidate`;
    return axios
        .get(logoutUrl, {
            params: {
                token,
            },
        })
        .catch((error) => {
            //shallow
            return Promise.resolve(true);
        });
};

const refresh = (token) => {
    const refreshUrl = `${apiBaseUrl}/auth/refresh`;
    return axios
        .post(refreshUrl, {
            token,
        })
        .catch((error) => {
            return handleServerError(error, "Unexpected error ocurred.");
        });
};

const refreshAdmin = (token) => {
    const refreshUrl = `${apiBaseUrl}/auth/admin/refresh`;
    return axios
        .post(refreshUrl, {
            token,
        })
        .catch((error) => {
            return handleServerError(error, "Unexpected error ocurred.");
        });
};

const getProfile = () => {
    const meUrl = `${apiBaseUrl}/auth/me`;
    return axiosWithToken.get(meUrl).catch((error) => {
        return handleServerError(error, "Unexpected error ocurred.");
    });
}

const updateProfile = (profile) => {
    profile.phone = profile?.phone?.replace(/\D/g, "");
    const meUrl = `${apiBaseUrl}/auth/me`;
    return axiosWithToken.put(meUrl,profile).catch((error) => {
        return handleServerError(error);
    });
}

export { login, loginWithUsername, activate, restorePassword, resetPassword, logout, refresh, refreshAdmin, getProfile, updateProfile, changePassword };
