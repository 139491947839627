import React, { useState } from 'react';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { LockTwoTone, HomeTwoTone } from '@ant-design/icons';
import { SimpleLayout } from "../../@theme/layout/auth/SimpleLayout";
import { useAuth } from '../../@hooks/useAuth';
import { useHistory, useLocation } from 'react-router';

import './LoginPage.less';
import { Errors } from '../../@theme/components/Errors';
import { useQuery } from '../../@hooks/useQuery';
import { Alert } from 'antd';
import Title from 'antd/lib/typography/Title';

function AdminLoginPageComponent() {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();
    const [loginError, setLoginError] = useState(null);
    const [loading, setLoading] = useState(false);
    const query = useQuery();
    let { from } = location.state || { from: { pathname: query.get('return') || "/admin" } };
    
    return (
        <SimpleLayout pageTitle="Admin Login" className="login-page">
            <Title style={{textAlign:'center'}} level={4}>Admin Login</Title>
            {loginError && <React.Fragment><Errors error={loginError} /><br/></React.Fragment>}
            <ProForm
                onFinish={async (values) => {
                    setLoginError(null);
                    setLoading(true);
                    auth.signin(values)
                    .then((user)=>{
                        setLoading(false);
                        history.replace(from);
                    })
                    .catch((error)=>{
                        setLoading(false);
                        if(error && error.message){
                            setLoginError(error);
                        }else{
                            setLoginError({message:'Unexpected login error occurred. Try again later.'});
                        }
                    });
                    
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'Login',
                    },
                    render: (_, dom) => dom.pop(),
                    submitButtonProps: {
                        size: 'large',
                        block: true,
                        loading: loading
                    },
                }}
            >


                <ProFormText
                    fieldProps={{
                        size: 'large',
                        prefix: <HomeTwoTone />
                        
                    }}
                    name="username"
                    placeholder="Enter your user name"
                    rules={[
                        {
                            required: true,
                            message: 'Enter your user name.',
                        },
                    ]}
                />
                <ProFormText
                    fieldProps={{
                        size: 'large',
                        prefix: <LockTwoTone />,
                        type: 'password'
                    }}

                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Enter your password.',
                        },
                    ]}
                    placeholder="Enter your password"

                />
            </ProForm>
            
        </SimpleLayout>
    );
};

export { AdminLoginPageComponent as AdminLoginPage };