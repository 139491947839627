import { Alert } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

function ValidationErrors({ errors }) {
    const errorsList = errors.errors;
    return (
        <Alert
            message="Oops, error occurred."
            description={
                errorsList &&
                Object.values(errorsList).map((fieldErrors, idx) => (
                    <Paragraph key={idx}>{fieldErrors[0]}</Paragraph>
                ))
            }
            type="error"
            showIcon
        />
    );
}

export { ValidationErrors };
