import { Card, Layout } from "antd";
import { Helmet } from "react-helmet";
import "./SimpleLayout.less";

const { Content, Footer } = Layout;

function SimpleLayout({ pageTitle, className, helmetChildren, children }) {
    return (
        <Layout className={`${className} simple-layout`} style={{ minHeight: "100vh" }}>
            <Helmet>
                <title>{pageTitle} - UniqueVtour Client Portal</title>
                {helmetChildren}
            </Helmet>

            <Content className="site-layout">
                <Card
                    className="site-layout-background site-layout-container"
                    bordered
                    style={{
                        margin: "auto",
                    }}
                >
                    <h1
                        title="Unique Vtour Client Portal"
                        className="logo"
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <span className="uv-icon-unique-tour-logo"></span>
                        Client Portal
                    </h1>
                    {children}
                </Card>
            </Content>

            <Footer style={{ textAlign: "center" }}>Unique Vtour ©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

export { SimpleLayout };
