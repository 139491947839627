import ProCard from "@ant-design/pro-card";
import { Button, Card, Descriptions, Divider, Steps } from "antd";

import DescriptionsItem from "antd/lib/descriptions/Item";
import Paragraph from "antd/lib/typography/Paragraph";

import Text from "antd/lib/typography/Text";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { formatTimestamp } from "../../../utils/format";
const {Step} = Steps;

const BookingSummaryCard = ({loading=false, hoverable=false, title, booking, stepsHidden=false, extraHidden=false, showRemark=false, bordered=false}) => {

    const currentStep = booking?booking.current_step||0:0;
    const {property} = booking||{};
    const history = useHistory();

    const cardClickHandler = (evt) => {
        if(booking){
            evt.preventDefault();
            history.push(`/my-bookings/${booking.id}`);
        }
    };
    
    if(booking){
        return (
            <ProCard hoverable={hoverable} onClick={hoverable?cardClickHandler:null} className="booking-summary-card" bordered={bordered} direction="column" headerBordered loading={loading} title={title} extra={ !extraHidden && <Link to={`/my-bookings/${booking.id}`}><Button type="primary">Details</Button></Link>}>
                
                <Descriptions layout="vertical" labelStyle={{color:"#8c8c8c"}} contentStyle={{fontSize:"1.25rem", marginBottom:"15px"}}>
                    <DescriptionsItem label="Reference #"><Text strong underline>{booking.invoice_number}</Text>
                    </DescriptionsItem>
                    <DescriptionsItem label="Property info">
                    <Paragraph>
                    {property.address1}<br/>
                    {property.address2 && <React.Fragment>{property.address2}<br/></React.Fragment>}
                    {`${property.postal_code}, ${property.city}`}
                    </Paragraph>
                    </DescriptionsItem>
                    <DescriptionsItem label="Property Size"><Text strong>{property.size}</Text>
                    </DescriptionsItem>
                    { showRemark && property.remark &&
                        <DescriptionsItem span={3} label="Remark"><Text >{property.remark}</Text>
                        </DescriptionsItem>
                    }
                    
                </Descriptions>
                
                {!stepsHidden && 
                    <React.Fragment>
                        <Divider/>
                        <Steps current={currentStep} responsive>
                            <Step title="Submission" description={<Paragraph>Booking submitted at {formatTimestamp({timestamp:booking.created_at})}</Paragraph>} />
                            <Step title="Confirm Booking" description="Booking to be confirmed by our staff." />
                            <Step title="Ready" description="Virtual Tour is ready to use." />
                        </Steps>
                    </React.Fragment>
                }
                
            </ProCard>
        );
    }

    return <Card loading={loading}></Card>;
}

export { BookingSummaryCard};