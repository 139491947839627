import React from 'react';
import { Button, Drawer, Empty, List, Skeleton } from "antd";
import { getShoppingCartSummary } from '../../../services/orderService';
import { connect } from 'react-redux';
import { removeItem, refreshOrderSummary } from '../../../store/shoppingCart';
import './ShoppingCartDrawer.less'
import { formatCurrency } from '../../../utils/format';
import { CloseOutlined, ShoppingTwoTone } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { withAuth } from '../../../components/withAuth';

function ShoppingCartSummary({ summary, onRemoveItem }) {
    if (summary && summary.lineItems)
        return (<List
            bordered={false}
            dataSource={summary.lineItems}
            renderItem={item => (
                <List.Item key={item.id}

                >
                    <List.Item.Meta
                        title={item.name}
                        description={<Button onClick={(e) => { onRemoveItem && onRemoveItem({ id: item.id }) }} className="list-action-btn-delete" danger type="link" size="small" key="list-action-delete" >delete<CloseOutlined /></Button>}
                    />
                    <div>{formatCurrency(item.totalPrice)}

                    </div>
                </List.Item>
            )}
            footer={
                <section>
                    <div className="summary-item">
                        <div className="summary-item-label">Sub-Total:</div>
                        <div className="summary-item-value">{formatCurrency(summary.subtotal)}</div>
                    </div>
                    <div className="summary-item">
                        <div className="summary-item-label">{summary.tax.name}:</div>
                        <div className="summary-item-value">{formatCurrency(summary.tax.amount)}</div>
                    </div>
                    <div className="summary-item">
                        <div className="summary-item-label">Total:</div>
                        <div className="summary-item-value"><strong>{formatCurrency(summary.total)}</strong></div>
                    </div>
                </section>
            }
        ></List>);
    else
        return <Empty description={"No item(s) selected"} />
}

class ShoppingCartDrawer extends React.Component {

    state = {
        loading: false
    };

    constructor(props = { visible: false, onClose: (e) => { } }) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.visible !== prevProps.visible && this.props.visible) {
            this.refreshSummary();
        } else if (this.props.visible && this.props.shoppingCartItems.length !== prevProps.shoppingCartItems.length) {
            this.refreshSummary();
        }
    }

    componentDidMount() {
        if (this.props.visible) {
            this.refreshSummary();
        }
    }

    refreshSummary() {
        if (this.state.loading)
            return;
        const { shoppingCartItems, summary } = this.props;
        //const summary = this.state.summary;
        const hasShoppingCartItems = shoppingCartItems !== null && shoppingCartItems.length > 0;
        let shouldRefreshFromApi = false;
        if (summary === null && hasShoppingCartItems) {
            shouldRefreshFromApi = true;

        } else {
            if (summary != null && hasShoppingCartItems) {
                if (summary.lineItems.length !== shoppingCartItems.length) {
                    shouldRefreshFromApi = true;
                } else {
                    const summaryItems = [...summary.lineItems];
                    for (const cartItem of shoppingCartItems) {
                        if (summaryItems.indexOf(i => i.id === cartItem.id) > -1) {
                            shouldRefreshFromApi = true;
                            break;
                        }
                    }
                }
            } else if (hasShoppingCartItems === false) {
                this.props.refreshOrderSummary(null);
            }
        }

        if (shouldRefreshFromApi) {
            this.setState({
                loading: true
            });
            getShoppingCartSummary(this.props.shoppingCartItems).then(rs => {
                this.props.refreshOrderSummary(rs);
            }).finally(() => {
                this.setState({
                    loading: false
                });
            });
        }

        return false;
    }

    render() {
        const { visible, onClose, history, summary } = this.props;
        const { loading } = this.state;
        const submitPath = this.props.auth?.admin ? "/admin/bookings/submit": "/bookings/submit";
        return (<Drawer width={null} className="shopping-cart-drawer" visible={visible} onClose={onClose} title={<span><ShoppingTwoTone /> Shopping Cart Summary</span>} footer={<Button onClick={() => { onClose(); history.push(submitPath); }} disabled={!summary || !summary.lineItems} type="primary" block size="large">Submit booking</Button>}>
            {loading ? <Skeleton loading={loading} /> : <ShoppingCartSummary summary={summary} onRemoveItem={this.props.removeItem} />}
        </Drawer>)
    }
}

ShoppingCartDrawer.defaultProps = {
    visible: true
}



const mapStateToProps = state => ({
    shoppingCartItems: state.shoppingCart.items,
    summary: state.shoppingCart.orderSummary
});

const mapDispatchToProps = {
    removeItem,
    refreshOrderSummary
}

const ShoppingCartDrawerComp = withAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(ShoppingCartDrawer)));

export { ShoppingCartDrawerComp as ShoppingCartDrawer, ShoppingCartSummary }