import React, { useState } from "react";
import { Alert, Button, Result, Space } from "antd";
import * as AuthService from "../../services/authService";
import { SimpleLayout } from "../../@theme/layout/auth/SimpleLayout";
import { Link } from "react-router-dom";
import ProForm, { ProFormText } from "@ant-design/pro-form";
import { MailTwoTone } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { Errors } from "../../@theme/components/Errors";

function ForgotPasswordPageComponent() {
    const [restorePasswordResult, setRestorePasswordResult] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    return (
        <SimpleLayout pageTitle="Forgot password">
            <Space direction="vertical">
                {restorePasswordResult === true && (
                    <Result status="success" title="An email that contains the instruction to reset your password has been sent. Please check your inbox.">
                        <Link to="/login">
                            <Button type="primary" block size="large">
                                Login now
                            </Button>
                        </Link>
                    </Result>
                )}
                {restorePasswordResult !== true && (
                    <ProForm
                        onFinish={async (values) => {
                            setRestorePasswordResult(null);
                            setError(null);
                            setLoading(true);
                            AuthService.restorePassword(values.email)
                                .then(() => {
                                    setRestorePasswordResult(true);
                                })
                                .catch((error) => {
                                    if (error && error.message) {
                                        setError(error);
                                    } else {
                                        setError({message: "Unexpected error occurred, we were not able to process your password reset request. Try again later."});
                                    }
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                        submitter={{
                            searchConfig: {
                                submitText: "Submit",
                            },
                            render: (_, dom) => dom.pop(),
                            submitButtonProps: {
                                size: "large",
                                block: true,
                                loading: loading,
                            },
                        }}
                    >
                        <Space direction="vertical">
                            <Title style={{ textAlign: "center" }} level={3}>
                                {" "}
                                Reset password
                            </Title>
                            {error && (
                                <React.Fragment>
                                    <Errors error={error} />
                                    <br />
                                </React.Fragment>
                            )}
                            <Alert showIcon type="info" message="Enter your user account's verified email address and we will send you a password reset link."></Alert>

                            <ProFormText
                                fieldProps={{
                                    size: "large",
                                    prefix: <MailTwoTone />,
                                    type: "email",
                                }}
                                label="Email address"
                                name="email"
                                placeholder="Enter your email address"
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter your email address.",
                                    },
                                    {
                                        type: "email",
                                        message: "Email address is not valid.",
                                    },
                                ]}
                            />
                        </Space>
                    </ProForm>
                )}
                <Link className="" to="/login">
                    <Button block type="link">
                        Back to login
                    </Button>
                </Link>
            </Space>
        </SimpleLayout>
    );
}

export { ForgotPasswordPageComponent as ForgotPasswordPage };
