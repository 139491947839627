import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import shoppingCart from "./shoppingCart";
import bookingsCatalog from "./catalog";

const reducer = combineReducers({
    shoppingCart,
    bookingsCatalog
})

const store = configureStore({
    reducer,
})

export default store;