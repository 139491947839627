import React, { useState } from "react";

import ProForm, { StepsForm, ProFormText, ProFormSelect, ProFormTextArea } from "@ant-design/pro-form";
import { Button, Divider, Modal, Radio, Result} from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addItem, removeItem, saveBookingDetails, clearShoppingCart } from "../../store/shoppingCart";

import { createOrder, createUserOrder, createUserOrderByAdmin } from "../../services/orderService";

import "./BookingSubmissionPage.less";
import { ValidationErrors } from "../../@theme/components/ValidationErrors";
import Paragraph from "antd/lib/typography/Paragraph";
import { withAuth } from "../../components/withAuth";
import { MaskedInput } from "antd-mask-input";
import { searchUsers } from "../../services/userAdminService";
import { DebounceSelect } from "../../components/DebounceSelect";

function PropertyForm() {
    return (
        <div>
            <ProForm.Group title="Address">
                <ProFormText requiredMark name={["property", "address1"]}  label="Address line 1" tooltip="e.g., street #, street name, or/and unit/suite #" placeholder="Enter Address line 1" rules={[{ required: true }]} />
                <ProFormText
                    name={["property", "address2"]}
                    
                    label="Address line 2"
                    tooltip="e.g., additional address info"
                    placeholder="Enter Address line 2"
                    //rules={[{ required: true }]}
                />
                <ProFormText requiredMark name={["property", "postalCode"]} width="sm" label="Postal Code" tooltip="A valid postal code, e.g., M1M 1M1" placeholder="Enter Postal Code" rules={[{ required: true }]} />
                <ProFormText requiredMark name={["property", "city"]} width="md" label="City" placeholder="Enter City" rules={[{ required: true }]} />
            </ProForm.Group>

            <ProForm.Group title="Miscellaneous">
                <ProFormSelect
                    placeholder="Select property size"
                    requiredMark
                    options={[
                        { value: "0-1500 sq.ft.", label: "0-1500 sq.ft." },
                        {
                            value: "1500-2500 sq.ft.",
                            label: "1500-2500 sq.ft.",
                        },
                        {
                            value: "2500-3500 sq.ft.",
                            label: "2500-3500 sq.ft.",
                        },
                        {
                            value: "3500-4500 sq.ft.",
                            label: "3500-4500 sq.ft.",
                        },
                        {
                            value: "4500-5500 sq.ft.",
                            label: "4500-5500 sq.ft.",
                        },
                        {
                            value: "5500-7000 sq.ft.",
                            label: "5500-7000 sq.ft.",
                        },
                        {
                            value: "More than 7000 sq.ft.",
                            label: "More than 7000 sq.ft.",
                        },
                    ]}
                    rules={[{ required: true }]}
                    width="md"
                    name={["property", "propertySize"]}
                    label="Size"
                />
                <ProFormTextArea name={["property", "remark"]} label="Remark/Notes" width="lg" placeholder="Additional Remark or Notes" />
            </ProForm.Group>
        </div>
    );
}

function LoggedInUserForm({ auth }) {
    return (
        <div>
            <ProForm.Group title="Confirm contact details">
                <ProFormText requiredMark readonly name={["contact", "name"]} label="First Name" width="md" placeholder="Your First Name" rules={[{ required: true }]} />
                <ProFormText requiredMark readonly name={["contact", "surname"]} label="Last Name" width="md" placeholder="Your Last Name" rules={[{ required: true }]} />
                <ProFormText requiredMark readonly name={["contact", "email"]} fieldProps={{ type: "email" }} label="Email" width="lg" placeholder="Your Email Address" rules={[{ required: true }]} />
                
                <ProForm.Item name={["contact", "phone"]} label="Phone Number" rules={[{ required: true }]} required requiredMark >
                    <MaskedInput  mask="(111) 111-1111" type="tel" inputMode="tel" placeholder="Contact Phone Number" />
                </ProForm.Item>
            </ProForm.Group>
        </div>
    );
}

function AdminSelectUserForm({ disabled }) {
    
    return (
        <div>
            <ProForm.Group title="Confirm contact details">
                <ProForm.Item name={["contact", "userId"]} label="User for the booking" rules={[{ required: !disabled, message: "Please select a user!" }]} required={!disabled} requiredMark={!disabled}>
                    <DebounceSelect
                        disabled={disabled}
                        placeholder="Select a user"
                        fetchOptions={async (term) => {
                            const data = await searchUsers(term)
                                .then((resp) => {
                                    if (resp.data) {
                                        return resp.data.map((ele, idx) => {
                                            return { label: `${ele.name} ${ele.surname} - ${ele.email} (${ele.phone})`, value: ele.id };
                                        });
                                    }
                                    return null;
                                })
                                .catch((err) => {
                                    console.log(err);
                                    return null;
                                });
                            return data;
                        }}
                        clearIcon
                        debounceTimeout={500}
                        style={{
                            minWidth: "350px",
                        }}
                    />
                </ProForm.Item>
            </ProForm.Group>
        </div>
    );
}

function AdminUserFormBody(){
    return (
        <div style={{ marginTop: "25px" }}>
            <ProForm.Group title="Enter user account details" tooltip="User can view the booking status and history with the registered account.">
                <ProFormText requiredMark name={["contact", "newUser", "name"]} label="First Name" width="md" placeholder="User First Name" rules={[{ required: true }]} />
                <ProFormText requiredMark name={["contact", "newUser", "surname"]} label="Last Name" width="md" placeholder="User Last Name" rules={[{ required: true }]} />
                <ProForm.Item name={["contact", "newUser", "phone"]} label="Phone Number" rules={[{ required: true }]} required requiredMark>
                    <MaskedInput mask="(111) 111-1111" type="tel" inputMode="tel" placeholder="Contact Phone Number" />
                </ProForm.Item>
                <ProFormText requiredMark fieldProps={{ type: "email" }} name={["contact", "newUser", "email"]} label="Email" width="lg" placeholder="User Email Address" rules={[{ required: true }]} />
                <ProFormText requiredMark fieldProps={{ type: "password" }} name={["contact", "newUser", "password"]} label="Password" width="lg" placeholder="Default Account Password" rules={[{ required: true }]} />
            </ProForm.Group>
        </div>
    );
}

function AdminUserForm() {
    const [showUserForm, setShowUserForm] = useState(false);
    return (
        <React.Fragment>
            <AdminSelectUserForm disabled={showUserForm} />
            <Radio.Group 
                onChange={(evt)=>setShowUserForm(evt.target.value)} 
                defaultValue={showUserForm}
                optionType="button"
                buttonStyle="solid"
                >
                <Radio.Button value={false}>Select existing user</Radio.Button>
                <Radio.Button value={true}>Add new user</Radio.Button>
            </Radio.Group>
            {showUserForm && <AdminUserFormBody />}
        </React.Fragment>
    );
}

function UserForm() {
    const [isNewUser, setIsNewUser] = useState(null);
    return (
        <div>
            <ProForm.Group title="Are you booking for the first time?">
                <Radio.Group name="isNewUser" defaultValue={isNewUser} onChange={(e) => setIsNewUser(e.target.value)}>
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
            </ProForm.Group>
            <Divider></Divider>
            {isNewUser && (
                <ProForm.Group title="Register your account with the booking" tooltip="You can view the booking status and history with the registered account.">
                    <ProFormText requiredMark name={["contact", "name"]} label="First Name" width="md" placeholder="Your First Name" rules={[{ required: true }]} />
                    <ProFormText requiredMark name={["contact", "surname"]} label="Last Name" width="md" placeholder="Your Last Name" rules={[{ required: true }]} />
                    <ProForm.Item name={["contact", "phone"]} label="Phone Number" rules={[{ required: true }]} required requiredMark >
                        <MaskedInput  mask="(111) 111-1111" type="tel" inputMode="tel" placeholder="Contact Phone Number" />
                    </ProForm.Item>
                    <ProFormText requiredMark fieldProps={{ type: "email" }} name={["contact", "email"]} label="Email" width="lg" placeholder="Your Email Address" rules={[{ required: true }]} />
                    <ProFormText requiredMark fieldProps={{ type: "password" }} name={["contact", "password"]} label="Password" width="lg" placeholder="Account Password" rules={[{ required: true }]} />
                </ProForm.Group>
            )}
            {isNewUser === false && <Link to={{pathname:'/login',state:{from:{pathname:'/bookings/submit'}}}}>Login now with existing account</Link>}
        </div>
    );
}

class BookingSubmissionPage extends React.Component {
    state = {
        visible: true,
        finished: false,
        errors: null,
        loading: false,
        bookingResult: null,
    };

    componentDidMount() {
        const { history } = this.props;
        const { bookingItems } = this.props;
        if(!bookingItems || bookingItems.length===0){
            const isAdmin = this.props.auth?.admin === true;
            const bookingsPath = isAdmin ? "/admin/bookings": "/bookings";
            history.replace(bookingsPath);
        }
    }


    render() {
        const { history, auth } = this.props;
        const { bookingItems, propertyDetails, contactDetails } = this.props;
        const isLoggedIn = !!auth.user;
        const isAdmin = auth.admin === true;
        const bookingsPath = isAdmin ? "/admin/bookings": "/bookings";
        return (
            <StepsForm
                onFinish={async (values) => {
                    this.props.saveBookingDetails(values);
                    this.setState({ errors: null, loading: true, bookingResult: null });
                    const bookingOrder = { ...values };
                    let orderPromise = null;
                    if (isLoggedIn) {
                        if(isAdmin){
                            orderPromise = createUserOrderByAdmin(bookingItems, bookingOrder);
                        }else{
                            orderPromise = createUserOrder(bookingItems, bookingOrder).then((resp) => {
                                auth.clearProfileCache();
                                return resp;
                            });
                        }
                    } else {
                        orderPromise = createOrder(bookingItems, bookingOrder);
                    }

                    return orderPromise
                        .then((resp) => {
                            this.setState({ finished: true, bookingResult: resp.data });
                            this.props.clearShoppingCart({});
                        })
                        .catch((errors) => {
                            if (errors.response && errors.response.data) {
                                this.setState({
                                    errors: errors.response.data,
                                });
                            }
                        })
                        .finally(() => {
                            this.setState({ loading: false });
                        });
                }}
                formProps={{
                    validateMessages: {
                        required: "'${label}' is Required!",
                    },
                }}
                submitter={{
                    render: ({ form, onSubmit, step, onPre }) => {
                        return [
                            step > 0 && !this.state.finished && (
                                <Button
                                    key="pre"
                                    onClick={() => {
                                        onPre?.();
                                    }}
                                    loading={this.state.loading}
                                >
                                    {" "}
                                    Back
                                </Button>
                            ),
                            !this.state.finished && (
                                <Button
                                    key="next"
                                    type="primary"
                                    onClick={() => {
                                        onSubmit?.();
                                    }}
                                    loading={this.state.loading}
                                >
                                    Continue
                                </Button>
                            ),
                        ];
                    },
                }}
                stepsFormRender={(dom, submitter) => {
                    return (
                        <Modal
                            title="Submit booking"
                            width={800}
                            onCancel={() => {
                                history.replace(bookingsPath);
                            }}
                            className="booking-submission-forms"
                            visible={true}
                            footer={submitter}
                            destroyOnClose
                            maskClosable={false}
                        >
                            {this.state.finished ? (
                                <Result
                                    status="success"
                                    title="Successfully Submitted your booking request!"
                                    subTitle={`Your reference number is: ${this.state.bookingResult.booking.referenceNumber}, our staff will be reaching out to you to confirm the booking.`}
                                    extra={isAdmin?null:[
                                        <Paragraph key="note1">If you are booking for the first time, an email verification to confirm your email address will also be sent.</Paragraph>,
                                        <Link to="/"><Button type="primary" key="login" size="large">
                                            {isLoggedIn?"Check the booking now":"Login to check the booking"}
                                        </Button>
                                        </Link>,
                                    ]}
                                />
                            ) : (
                                <React.Fragment>
                                    {this.state.errors && (
                                        <React.Fragment>
                                            <ValidationErrors errors={this.state.errors} />
                                            <br />
                                        </React.Fragment>
                                    )}
                                    {dom}
                                </React.Fragment>
                            )}
                        </Modal>
                    );
                }}
            >
                <StepsForm.StepForm
                    name="propertyDetails"
                    title="Property Details"
                    initialValues={{ property: { ...propertyDetails } }}
                    onFinish={async (values) => {
                        this.props.saveBookingDetails(values);
                        return true;
                    }}
                >
                    <PropertyForm />
                </StepsForm.StepForm>

                <StepsForm.StepForm name="userDetails" title="Contact Details" initialValues={isLoggedIn && !isAdmin ? { contact: { ...auth.user } } : { contact: { ...contactDetails } }}>
                    {isLoggedIn === false && <UserForm />}
                    {isLoggedIn && isAdmin === false && <LoggedInUserForm auth={auth} />}
                    {isLoggedIn && isAdmin === true && <AdminUserForm/>}
                </StepsForm.StepForm>
            </StepsForm>
        );
    }
}

const mapStateToProps = (state) => ({
    bookingItems: state.shoppingCart.items,
    //bookingDetails: state.shoppingCart.bookingDetails,
    propertyDetails: state.shoppingCart.bookingDetails.property,
    contactDetails: state.shoppingCart.bookingDetails.contact,
});

const mapDispatchToProps = {
    addItem,
    removeItem,
    saveBookingDetails,
    clearShoppingCart,
};

const BookingSubmissionPageWithRouter = withAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingSubmissionPage)));
export { BookingSubmissionPageWithRouter as BookingSubmissionPage };
