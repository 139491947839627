import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'bookingsCatalog',
    initialState: {
        catalog: null
    },
    reducers: {
        updateCatalog: (state, action) => {
            state.catalog = action.payload;
        },
        updateBundleSelectedItem: (state, action) => {
            const { id, selected } = action.payload;
            let bundles = { ...state.catalog.bundles };
            const itemIndex = bundles.items.findIndex(i => i.id === id);
            if (itemIndex > -1) {
                bundles.items[itemIndex].selected = selected;
                state.catalog.bundles = bundles;
            }
        }
    }
});

export const { updateCatalog, updateBundleSelectedItem } = slice.actions;


export default slice.reducer;