import React from 'react';
import { SmileOutlined, TabletOutlined, UserOutlined, LogoutOutlined, FormOutlined } from '@ant-design/icons';

import { LogoutLink } from "../../components/LogoutLink";

export default {
  route: {
    path: '/admin',
    routes: [
      {
        path: '/admin/welcome',
        name: 'Welcome',
        icon: <SmileOutlined />,
        
      },
      
      {
        name: 'New Booking',
        icon: <FormOutlined />,
        path: '/admin/bookings'
      },
      
      {
        name: 'Logout',
        icon: <LogoutOutlined />,
        path:'/logout',
        component: LogoutLink,
      }
    ],
  },
  location: {
    pathname: '/admin',
  },
};