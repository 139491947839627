/**
 * Handles server-side errors
 * @param {any} error Error response object
 * @param {String} errorFallbackMessage The fallback message for error response in case of null or empty.
 * @param {String} defaultMessage The default error message when error object is neither a reponse nor contains message field.
 * @returns A rejected promise with the message
 */
const handleServerError = (error, errorFallbackMessage, defaultMessage = "Unexpected error occurred.") => {
    if (error && error.response) {
        if (error.response.data?.type === "error.validation") {
            if (error.response.data?.errors) {
                return Promise.reject(error.response.data);
            }
        }
        let errorMsg = errorFallbackMessage || error.response.data?.message;
        return Promise.reject({
            message: errorMsg || defaultMessage,
        });
    } else if (error && error.message) {
        return Promise.reject(error);
    } else {
        return Promise.reject({
            message: defaultMessage,
        });
    }
};

export { handleServerError };
