import './App.less';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { BookingsPage } from './pages/bookings/BookingsPage';
import { useAuth } from './@hooks/useAuth';
import { LoginPage } from './pages/auth/LoginPage';
import { ActivationPage } from './pages/auth/ActivationPage';
import { ForgotPasswordPage } from './pages/auth/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/auth/ResetPasswordPage';
import SidebarLayout from './@theme/layout/dashboard/SidebarLayout';
import { WelcomePage } from './pages/portal/WelcomePage';
import { BookingDetailsPage } from './pages/portal/BookingDetailsPage';
import { BookingsListPage } from './pages/portal/BookingsListPage';
import { ProfilePage } from './pages/portal/ProfilePage';
import { AdminLoginPage } from './pages/auth/AdminLoginPage';
import { AdminWelcomePage } from './pages/portal/admin/AdminWelcomePage';

function App() {
    return (
        <Router>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/bookings">
                    <BookingsPage />
                </Route>
                <Route path="/users">
                    <p>/users</p>
                </Route>
                <Route path="/login">
                    <LoginPage />
                </Route>
                <Route path="/activation">
                    <ActivationPage />
                </Route>
                <Route path="/forgot-password">
                    <ForgotPasswordPage />
                </Route>
                <Route path="/reset-password">
                    <ResetPasswordPage />
                </Route>

                <Route path="/admin/login">
                    <AdminLoginPage/>
                </Route>

                <PrivateRoute path="/admin/bookings" adminOnly redirectPathname="/admin/login">
                    <BookingsPage/>
                </PrivateRoute>

                <PrivateRoute path="/admin" adminOnly redirectPathname="/admin/login">
                    <SidebarLayout>
                        <Switch>
                            
                            <Route path="/admin/welcome">
                                <AdminWelcomePage/>
                            </Route>

                            <Route exact path="/admin">
                                <Redirect to="/admin/welcome"/>
                            </Route>
                        </Switch>
                    </SidebarLayout>
                </PrivateRoute>

                <PrivateRoute path="/">
                    <SidebarLayout>
                        <Switch>
                            
                            <Route exact path="/welcome">
                                <WelcomePage/>
                            </Route>
                            
                            <Route exact path="/my-bookings">
                                <BookingsListPage/>
                            </Route>

                            <Route exact path="/profile">
                                <ProfilePage/>
                            </Route>

                            <Route path="/my-bookings/:bookingId">
                                <BookingDetailsPage/>
                            </Route>

                            <Route exact path="/">
                                <Redirect to="/welcome"/>
                            </Route>
                        </Switch>
                    </SidebarLayout>
                </PrivateRoute>

                
            </Switch>

        </Router>
    );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ redirectPathname, adminOnly, children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user && (adminOnly ? auth.admin: true) ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: redirectPathname||'/login',
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default App;
