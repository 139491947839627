import React, { useState } from 'react';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { MailTwoTone, LockTwoTone } from '@ant-design/icons';
import { SimpleLayout } from "../../@theme/layout/auth/SimpleLayout";
import { useAuth } from '../../@hooks/useAuth';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import './LoginPage.less';
import { Errors } from '../../@theme/components/Errors';
import { useQuery } from '../../@hooks/useQuery';

function LoginPageComponent() {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();
    const [loginError, setLoginError] = useState(null);
    const [loading, setLoading] = useState(false);
    const query = useQuery();
    let { from } = location.state || { from: { pathname: query.get('return') || "/" } };
    
    return (
        <SimpleLayout pageTitle="Login" className="login-page">
            {loginError && <React.Fragment><Errors error={loginError} /><br/></React.Fragment>}
            <ProForm
                onFinish={async (values) => {
                    setLoginError(null);
                    setLoading(true);
                    auth.signin(values)
                    .then((user)=>{
                        setLoading(false);
                        history.replace(from);
                    })
                    .catch((error)=>{
                        setLoading(false);
                        if(error && error.message){
                            setLoginError(error);
                        }else{
                            setLoginError({message:'Unexpected login error occurred. Try again later.'});
                        }
                    });
                    
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'Login',
                    },
                    render: (_, dom) => dom.pop(),
                    submitButtonProps: {
                        size: 'large',
                        block: true,
                        loading: loading
                    },
                }}
            >


                <ProFormText
                    fieldProps={{
                        size: 'large',
                        prefix: <MailTwoTone />,
                        type: 'email'
                    }}
                    name="email"
                    placeholder="Enter your email address"
                    rules={[
                        {
                            required: true,
                            message: 'Enter your email address.',
                        },
                        {
                            type: 'email',
                            message: 'Email address is not valid.',
                        },
                    ]}
                />
                <ProFormText
                    fieldProps={{
                        size: 'large',
                        prefix: <LockTwoTone />,
                        type: 'password'
                    }}

                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Enter your password.',
                        },
                    ]}
                    placeholder="Enter your password"

                />
            </ProForm>
            <div className="flex-col">
            <Link className="btn-forgot-password" to="/forgot-password">Forgot password?</Link>
            </div>
        </SimpleLayout>
    );
};

export { LoginPageComponent as LoginPage };