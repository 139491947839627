import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    orderSummary: null,
    items: [],
    bookingDetails: {
        property: null,
        contact: null,
    },
};
const slice = createSlice({
    name: "shoppingCart",
    initialState: initialState,
    reducers: {
        addItem: (state, action) => {
            state.items.push(action.payload);
        },
        removeItem: (state, action) => {
            if (action.payload) {
                const delIndex = state.items.findIndex(
                    (i) => i.id === action.payload.id
                );
                if (delIndex > -1) {
                    state.items.splice(delIndex, 1);
                    state.orderSummary = null;
                }
            }
        },
        refreshOrderSummary: (state, action) => {
            state.orderSummary = action.payload;
        },
        saveBookingDetails: (state, action) => {
            if (action.payload) {
                const { property, contact, user } = action.payload;
                if (property) {
                    state.bookingDetails.property = { ...property };
                }
                if (contact) {
                    state.bookingDetails.contact = { ...contact };
                }
                if (user) {
                    state.bookingDetails.user = { ...user };
                }
            }
        },
        clearShoppingCart: (state) => {
            Object.assign(state, initialState);
        },
    },
});

export const {
    addItem,
    removeItem,
    refreshOrderSummary,
    saveBookingDetails,
    clearShoppingCart,
} = slice.actions;

export default slice.reducer;
