import React from "react";
import { Alert, Divider, Modal } from "antd";
import { BookingsLayout } from "../../@theme/layout/bookings/BookingsLayout";
import { getBookingsCatalog } from "../../services/catalogService";
import { CategoryItems } from "./components/CategoryItems";
import "./BookingsPage.less";
import { ShoppingCartDrawer } from "./components/ShoppingCartDrawer";
import { connect } from "react-redux";
import { addItem, removeItem } from "../../store/shoppingCart";
import { updateCatalog, updateBundleSelectedItem } from "../../store/catalog";
import { ItemOptions } from "./components/ItemOptions";
import {
    Route,
    Switch,
    withRouter,
} from "react-router-dom";
import { BookingSubmissionPage } from "./BookingSubmissionPage";
import { withAuth } from "../../components/withAuth";
import Title from "antd/lib/typography/Title";
import { ServiceAreaTable } from "./components/ServiceAreasTable";

class BookingsPageComponet extends React.Component {
    state = {
        loading: true,
        shoppingCartVisible: false,
    };

    toggleShoppingCartVisible(visibleState) {
        this.setState({
            shoppingCartVisible: !visibleState,
        });
    }

    handleItemSelected(evt) {
        if (
            this.props.shoppingCartItems.findIndex(
                (i) => i.id === evt.item.id
            ) < 0
        ) {
            this.props.addItem(evt.item);
            //this.props.updateBundleSelectedItem({ id: evt.item.id, selected: true });
        }
    }

    handleRemoveBundleItem(evt) {
        if (evt.item) this.props.removeItem(evt.item);
    }

    handleItemOptionChanged(evt) {
        if (evt.lastSelected && evt.lastSelected.id)
            this.props.removeItem(evt.lastSelected);
    }

    componentDidMount() {
        if (
            !this.props.bookingsCatalog ||
            Object.keys(this.props.bookingsCatalog).length === 0
        ) {
            getBookingsCatalog().then((data) => this.props.updateCatalog(data));
        } else {
            this.setState({ loading: false });
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (
            this.props.bookingsCatalog !== null &&
            prevProps.bookingsCatalog === null
        ) {
            this.setState({ loading: false });
        }
    }

    handleShowItemOptions(item) {
        Modal.info({
            content: <ItemOptions itemWithOptions={item} />,
        });
    }

    render() {
        const { loading, shoppingCartVisible } = this.state;
        const bookingsCatalog = { ...this.props.bookingsCatalog };
        const submitPath = this.props.auth?.admin ? "/admin/bookings/submit": "/bookings/submit";
        return (
            <BookingsLayout
                pageTitle="Bookings"
                onShoppingCartBtnClick={() =>
                    this.toggleShoppingCartVisible(shoppingCartVisible)
                }
            >
                <div className="App">
                    <section className="catalog-section" id="packages">
                        <Title level={2}>Packages</Title>
                        <Divider />
                        <CategoryItems
                            loading={loading}
                            categoryItems={bookingsCatalog.packages}
                            onItemOptionChange={this.handleItemOptionChanged.bind(
                                this
                            )}
                            onClick={this.handleItemSelected.bind(this)}
                        />
                    </section>
                    <section className="catalog-section" id="add-ons">
                        <Title level={2}>
                            Add-ons
                        </Title>
                        <Divider />
                        <CategoryItems
                            loading={loading}
                            categoryItems={bookingsCatalog.addOns}
                            onItemOptionChange={this.handleItemOptionChanged.bind(
                                this
                            )}
                            onClick={this.handleItemSelected.bind(this)}
                        />
                    </section>

                    <section className="catalog-section" id="flyer">
                        <Title level={2}>Flyer/Brochure/Booklet</Title>
                        <Divider />
                        <CategoryItems
                            loading={loading}
                            categoryItems={bookingsCatalog.flyer}
                            onItemOptionChange={this.handleItemOptionChanged.bind(
                                this
                            )}
                            onClick={this.handleItemSelected.bind(this)}
                        />
                    </section>

                    {
                        bookingsCatalog?.serviceAreas && bookingsCatalog.serviceAreas.items && 
                        <section className="catalog-section" id="serviceAreas">
                            <Title level={2}>Service Areas</Title>
                            <Divider/>
                            <Alert type="warning" showIcon message="The cost will be confirmed by our staff before we add it to the final invoice."/><br/>
                            <ServiceAreaTable items={bookingsCatalog.serviceAreas.items[0].variations}/>
                        </section>                        
                    }
                </div>

                <ShoppingCartDrawer
                    visible={shoppingCartVisible}
                    onClose={() => this.toggleShoppingCartVisible(true)}
                />

                <Switch>
                    <Route path={submitPath}>
                        <BookingSubmissionPage />
                    </Route>
                </Switch>
            </BookingsLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    shoppingCartItems: state.shoppingCart.items,
    bookingsCatalog: state.bookingsCatalog.catalog,
});

const mapDispatchToProps = {
    addItem,
    removeItem,
    updateBundleSelectedItem,
    updateCatalog,
};

const BookingsPage = withAuth(connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BookingsPageComponet)));

export { BookingsPage };
