import React from 'react';
import { SmileOutlined, TabletOutlined, UserOutlined, LogoutOutlined, FormOutlined } from '@ant-design/icons';

import { LogoutLink } from "../../components/LogoutLink";

export default {
  route: {
    path: '/',
    routes: [
      {
        path: '/welcome',
        name: 'Welcome',
        icon: <SmileOutlined />,
        
      },
      {
        name: 'My Bookings',
        icon: <TabletOutlined />,
        path: '/my-bookings'
      },
      {
        name: 'New Booking',
        icon: <FormOutlined />,
        path: '/bookings'
      },
      {
        name: 'Profile',
        icon: <UserOutlined />,
        path:'/profile'
      },
      {
        name: 'Logout',
        icon: <LogoutOutlined />,
        path:'/logout',
        component: LogoutLink,
      }
    ],
  },
  location: {
    pathname: '/',
  },
};