import { Tag } from "antd";

const style = {fontSize:'1.05rem',lineHeight:'25px'};

const BookingStatus = ({status, large=false}) => {
    let statucColor = '';
    let statusLabel = status;
    
    switch (status) {
        case 'DRAFT':
            statucColor = '#005AD9';
            break;
        case 'UNPAID':
            statucColor = '#737373';
            break;
        case 'PAID':
            statucColor = '#38a100';
            break;
        default:
            statucColor = '#005AD9';
            break;
    }

    return (<Tag style={large?style:null} color={statucColor}>{statusLabel}</Tag>);
};

export {BookingStatus};